import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

import ChisiamoCard from "../../components/Card/ChisiamoCard";
import MoreInfoModal from "../../components/Modal/MoreInfoModal";

import Casoria from "../../assets/ChisiamoCardImages/Casoria.png";
import Modugno from "../../assets/ChisiamoCardImages/Modugno.png";

import Faber from "../../assets/SliderProductsImages/Incasso/FABER.jpeg";
import AEG from "../../assets/SliderProductsImages/Incasso/AEG.png";
import Blanco from "../../assets/SliderProductsImages/Incasso/Blanco.png";
import CandyLogo from "../../assets/SliderProductsImages/Incasso/CandyLogo.svg";
import Electrolux from "../../assets/SliderProductsImages/Incasso/Electrolux.png";
import Elleci from "../../assets/SliderProductsImages/Incasso/ELLECI.jpeg";
// import Icrolla from "../../assets/SliderProductsImages/Incasso/Icrolla.jpg";
// import Meneghetti from "../../assets/SliderProductsImages/Incasso/Meneghetti.png";
import Paini from "../../assets/SliderProductsImages/Incasso/Paini.png";
import Pyramis from "../../assets/SliderProductsImages/Incasso/Pyramis.png";
// import SanGiorgio from "../../assets/SliderProductsImages/LiberaInstallazione/SanGiorgio.jpg";
// import Elba from "../../assets/SliderProductsImages/LiberaInstallazione/Elba.png";
import Avintage from "../../assets/SliderProductsImages/LiberaInstallazione/Avintage.jpg";
import LG from "../../assets/SliderProductsImages/LiberaInstallazione/LG.png";
import LGBI from "../../assets/SliderProductsImages/Incasso/LGBI.jpeg";
import BERKEL from "../../assets/SliderProductsImages/LiberaInstallazione/BERKEL.jpeg";
import ElectroluxProfessional from "../../assets/SliderProductsImages/LiberaInstallazione/ElectroluxProfessional.png";
// import Remington from "../../assets/SliderProductsImages/PED/Remington.jpg";
// import Opes from "../../assets/SliderProductsImages/PED/Opes.jpg";
import Canali from "../../assets/SliderProductsImages/Accessori/Canali.png";
import Beko from "../../assets/SliderProductsImages/LiberaInstallazione/Beko.png";
import CIAC from "../../assets/SliderProductsImages/Accessori/CIAC.png";
import GRF from "../../assets/SliderProductsImages/Incasso/GRF.svg";
import Nikkei from "../../assets/SliderProductsImages/LiberaInstallazione/Nikkei.jpg";
import Hisense from "../../assets/SliderProductsImages/LiberaInstallazione/Hisense.png";

import "./chisiamo.css";

export default function Chisiamo() {
  const [isMoreInfoModalOpen, setIsMoreInfoModalOpen] = useState("");
  const [cards, setCards] = useState([
    {
      image: Casoria,
      title: "Campania",
      lunVen: "8:30 - 13:30 e 14:30 - 18:30",
      sab: "8:30 - 13:30",
    },
    { image: Modugno, title: "Puglia", lunVen: "8:30 - 13:30 e 15:30 - 18:30" },
  ]);

  // non esatti
  const concessionariLogos = [
    {
      logo: Electrolux,
      nome: "Elettrolux B.I.",
    },
    {
      logo: AEG,
      nome: "AEG B.I.",
    },
    {
      logo: CandyLogo,
      nome: "Candy",
    },
    {
      logo: LGBI,
      nome: "LG Incasso",
    },

    {
      logo: Faber,
      nome: "Faber",
    },
    {
      logo: Canali,
      nome: "Opes - Canali",
    },
    {
      logo: Blanco,
      nome: "Blanco",
    },
    {
      logo: Pyramis,
      nome: "Pyramis",
    },
    {
      logo: Paini,
      nome: "Paini",
    },
    {
      logo: CIAC,
      nome: "CIAC",
    },
    {
      logo: GRF,
      nome: "GRF",
    },
    {
      logo: Elleci,
      nome: "ELLECI",
    },
    {
      logo: Avintage,
      nome: "Avintage Cantine",
    },
  ];
  const distributoreLogos = [
    {
      logo: LG,
      nome: "LG F.S.",
    },
    // DeLonghi,
    {
      logo: Electrolux,
      nome: "Electrolux F.S.",
    },
    {
      logo: ElectroluxProfessional,
      nome: "Electrolux Professional",
    },
    {
      logo: AEG,
      nome: "AEG F.S.",
    },
    {
      logo: Nikkei,
      nome: "Nikkei",
    },
    {
      logo: Hisense,
      nome: "Hisense",
    },
    {
      logo: Beko,
      nome: "Beko",
    },
    {
      logo: BERKEL,
      nome: "Berkel",
    },
  ];

  const [offsetConcessionari, setOffsetConcessionari] = useState(0);
  const [offsetDistributori, setOffsetDistributori] = useState(0);

  // const logos = [...concessionariLogos, ...concessionariLogos.slice(0, 4)];
  useEffect(() => {
    const containerWidth =
      document.querySelector(".chisiamoSlider").clientWidth;
    const numLogos = Math.floor(containerWidth / 120);
    const interval = setInterval(() => {
      // If we reach the end, reset the offset to 0
      if (
        Math.abs(offsetConcessionari) >=
        (concessionariLogos.length - numLogos) * 120
      ) {
        setOffsetConcessionari(0);
      } else {
        setOffsetConcessionari((prevOffset) => prevOffset - 120);
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [offsetConcessionari]);

  useEffect(() => {
    const containerWidth =
      document.querySelector(".chisiamoSlider").clientWidth;
    const numLogos = Math.floor(containerWidth / 120);
    const interval = setInterval(() => {
      // If we reach the end, reset the offset to 0
      if (
        Math.abs(offsetDistributori) >=
        (distributoreLogos.length - numLogos) * 120
      ) {
        setOffsetDistributori(0);
      } else {
        setOffsetDistributori((prevOffset) => prevOffset - 120);
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [offsetDistributori]);

  return (
    <div className="chisiamo">
      <Navbar></Navbar>
      <h1>Chi siamo</h1>
      <h3>Le nostre filiali</h3>
      <p className="chisiamoText">
        Fondata nel 1971, la EUROLUX INCASSO s.r.l. è leader da 50 anni nella
        distribuzione per tutta la Campania, Puglia e Basilicata di
        Elettrodomestici da Incasso, e Accessori per Cucine Componibili
      </p>
      <div className="chisiamoCards">
        {cards.map((card) => (
          <ChisiamoCard
            image={card.image}
            title={card.title}
            lunVen={card.lunVen}
            sab={card.sab}
          ></ChisiamoCard>
        ))}
      </div>
      <div className="chisiamoSliders">
        <div
          className="chisiamoSlider"
          onClick={() => setIsMoreInfoModalOpen("Concessionari e Agenti")}
          // style={{ overflow: "hidden", width: "400px" }}
        >
          <h3>Concessionari e Agenti</h3>
          <p className="chisiamoSliderText">
            Un concessionario è autorizzato a vendere prodotti o servizi di
            un'altra azienda, offrendo al cliente finale i prezzi più
            vantaggiosi.
          </p>

          <div
            style={{
              display: "flex",
              transition: "transform 1s",
              transform: `translateX(${offsetConcessionari}px)`,
            }}
          >
            {concessionariLogos.map((logo, index) => (
              <img
                draggable="false"
                key={index}
                src={logo.logo}
                alt={logo.nome}
                className="chisiamoSliderImage"

                // style={{ width: "100px" }}
              />
            ))}
          </div>
        </div>
        <div
          className="chisiamoSlider"
          onClick={() => setIsMoreInfoModalOpen("Distributori")}
        >
          <h3>Distributori</h3>
          <p className="chisiamoSliderText">
            Un distributore acquista direttamente dai produttori e rivende ai
            dettaglianti o consumatori, offrendo così prezzi più competitivi.
          </p>

          <div
            style={{
              display: "flex",
              transition: "transform 1s",
              transform: `translateX(${offsetDistributori}px)`,
            }}
          >
            {distributoreLogos.map((logo, index) => (
              <img
                draggable="false"
                key={index}
                src={logo.logo}
                alt={logo.nome}
                className="chisiamoSliderImage"
              />
            ))}
          </div>
        </div>
      </div>
      {isMoreInfoModalOpen != "" ? (
        <MoreInfoModal
          logos={
            isMoreInfoModalOpen == "Concessionari e Agenti"
              ? concessionariLogos
              : distributoreLogos
          }
          isMoreInfoModalOpen={isMoreInfoModalOpen}
          setIsMoreInfoModalOpen={setIsMoreInfoModalOpen}
        ></MoreInfoModal>
      ) : null}
      <Footer></Footer>
    </div>
  );
}
