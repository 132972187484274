import React, { useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import ImageSlider from "../../components/ImageSlider/ImageSlider";
import ProductsSlider from "../../components/ImageSlider/ProductsSlider";
import CurrentProductIndicator from "../../components/ImageSlider/CurrentProductIndicator";
import CurrentImageIndicator from "../../components/ImageSlider/CurrentIndicator";
import Footer from "../../components/Footer/Footer";

import "./home.css";

import ELECTROLUX from "../../assets/SliderMainImages/ELECTROLUX.avif";
import ELECTROLUX2 from "../../assets/SliderMainImages/ELECTROLUX2.png";
import ELECTROLUX3 from "../../assets/SliderMainImages/ELECTROLUX3.webp";
import ELECTROLUX4 from "../../assets/SliderMainImages/ELECTROLUX4.webp";
import AEGSITO from "../../assets/SliderMainImages/AEGSITO.jpg";
import LGMAIN from "../../assets/SliderMainImages/LG.jpg";
import CANDY from "../../assets/SliderMainImages/CANDY.png";
import CANDYWARR from "../../assets/SliderMainImages/CANDYWARR.jpg";
import BEKO from "../../assets/SliderMainImages/BEKO.png";
import BEKO2 from "../../assets/SliderMainImages/BEKO2.jpg";
import FABER from "../../assets/SliderMainImages/FABER.jpg";
import BERKEL from "../../assets/SliderMainImages/BERKEL.png";
import ELLECI from "../../assets/SliderMainImages/ELLECI.jpg";
import HISENSE from "../../assets/SliderMainImages/HISENSE.jpg";
import GRFSITO from "../../assets/SliderMainImages/GRFSITO.png";
import LGSITO from "../../assets/SliderMainImages/LGSITO.png";
import LGINCASSO from "../../assets/SliderMainImages/LGINCASSO.png";
import SANGIORGIO from "../../assets/SliderMainImages/SANGIORGIO.png";
import CANDYPROMO from "../../assets/SliderMainImages/CANDYPROMO.jpg";
import AvintageVideo from "../../assets/SliderMainImages/AvintageVideo.mp4";

import Hisense from "../../assets/SliderProductsImages/LiberaInstallazione/Hisense.png";
import Nikkei from "../../assets/SliderProductsImages/LiberaInstallazione/Nikkei.jpg";
import AEG from "../../assets/SliderProductsImages/Incasso/AEG.png";
import GRF from "../../assets/SliderProductsImages/Incasso/GRF.svg";
import Faber from "../../assets/SliderProductsImages/Incasso/FABER.jpeg";
import Blanco from "../../assets/SliderProductsImages/Incasso/Blanco.png";
import CandyLogo from "../../assets/SliderProductsImages/Incasso/CandyLogo.svg";
import Electrolux from "../../assets/SliderProductsImages/Incasso/Electrolux.png";
import Elleci from "../../assets/SliderProductsImages/Incasso/ELLECI.jpeg";
import Icrolla from "../../assets/SliderProductsImages/Incasso/Icrolla.jpg";
import Meneghetti from "../../assets/SliderProductsImages/Incasso/Meneghetti.png";
import Paini from "../../assets/SliderProductsImages/Incasso/Paini.png";
import Pyramis from "../../assets/SliderProductsImages/Incasso/Pyramis.png";
import SanGiorgio from "../../assets/SliderProductsImages/LiberaInstallazione/SanGiorgio.jpg";
import Elba from "../../assets/SliderProductsImages/LiberaInstallazione/Elba.png";
import Avintage from "../../assets/SliderProductsImages/LiberaInstallazione/Avintage.jpg";
import LG from "../../assets/SliderProductsImages/LiberaInstallazione/LG.png";

import LGBI from "../../assets/SliderProductsImages/Incasso/LGBI.jpeg";
import Berkel from "../../assets/SliderProductsImages/LiberaInstallazione/BERKEL.jpeg";
import ElectroluxProfessional from "../../assets/SliderProductsImages/LiberaInstallazione/ElectroluxProfessional.png";

import Canali from "../../assets/SliderProductsImages/Accessori/Canali.png";
import SIDERPLAST from "../../assets/SliderProductsImages/Accessori/SIDERPLAST.jpeg";
import CIAC from "../../assets/SliderProductsImages/Accessori/CIAC.png";
import Beko from "../../assets/SliderProductsImages/LiberaInstallazione/Beko.png";

export default function Home() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);

  const [isPlaying, setIsPlaying] = useState(true);
  const [isProductsPlaying, setIsProductsPlaying] = useState(true);
  const images = [
    {
      video: AvintageVideo,
      link: "https://avintage.com/en/",
    },
    {
      image: CANDYPROMO,
      link: "https://candy-home.com/it_IT/promozioni/incasso-regala-lavatrice/",
    },
    {
      image: ELECTROLUX3,
      link: "https://www.electrolux.it/local/concorsi/green-action1/lavastoviglie/",
    },
    {
      image: ELECTROLUX4,
      link: "https://www.electrolux.it/local/concorsi/green-action1/lavastoviglie/",
    },
    {
      image: ELECTROLUX,
      link: "https://www.electrolux.it/local/concorsi/green-action1/lavastoviglie/",
    },
    {
      image: ELECTROLUX2,
      link: "https://www.electroluxprofessional.com/it/",
    },
    { image: GRFSITO, link: "https://www.grfdomestic.eu/" },
    { image: HISENSE, link: "https://www.hisense.it/" },
    { image: BERKEL, link: "https://www.theberkelworld.com/it" },
    { image: LGMAIN, link: "https://www.lg.com/it/" },
    {
      image: ELLECI,
      link: "https://www.elleci.com/it/",
    },
    { image: AEGSITO, link: "https://www.aeg.it/" },
    { image: CANDY, link: "https://www.candy-home.com/it_IT/" },
    { image: CANDYWARR, link: "https://www.candy-home.com/it_IT/" },
    { image: LGSITO, link: "https://www.lg.com/it/incasso/" },
    { image: LGINCASSO, link: "https://www.lg.com/it/incasso/" },
    { image: BEKO, link: "https://www.beko.com/it-it" },
    { image: BEKO2, link: "https://www.beko.com/it-it/promo/beko-e-lenor" },
    { image: FABER, link: "https://www.faberspa.com/" },
    { image: SANGIORGIO, link: "https://www.sangiorgioelettrodomestici.it/" },
  ];

  const products = [
    {
      title: "Linea Incasso",
      desc: "Leader nella distribuzione dei prodotti da incasso e concessionario esclusivo dei marchi: Electrolux - AEG – CANDY e collaborando con i principali produttori presenti sul territorio nazionale, riesce con un’ampia gamma di articoli a soddisfare tutte le richieste del mercato.",
      products: [
        { image: GRF, link: "https://www.grfdomestic.eu/" },
        { image: Faber, link: "https://www.faberspa.com/" },
        { image: AEG, link: "https://www.aeg.it/" },
        { image: Blanco, link: "https://www.blanco.com/it-it/" },
        { image: CandyLogo, link: "https://www.candy-home.com/it_IT/" },
        { image: Electrolux, link: "https://www.electrolux.it/" },
        { image: Elleci, link: "https://www.elleci.com/it/" },
        { image: Icrolla, link: "https://www.crolla.it/" },
        { image: Meneghetti, link: "http://www.meneghetti.it/" },
        { image: Paini, link: "https://www.paini.com/it" },
        { image: Pyramis, link: "https://www.pyramisgroup.it/it-IT/welcome/" },
        { image: LGBI, link: "https://www.lg.com/it/incasso/" },
      ],
    },
    {
      title: "Linea Libera Installazione",
      desc: "Grazie alla collaborazione con i principali produttori, e ad un ampia selezione di referenze riesce a soddisfare ogni esigenza di mercato sia Professional che Consumer",
      products: [
        { image: Electrolux, link: "https://www.electrolux.it/" },
        { image: AEG, link: "https://www.aeg.it/" },
        { image: GRF, link: "https://www.grfdomestic.eu/" },
        { image: Beko, link: "https://www.beko.com/it-it" },
        { image: Nikkei, link: "https://www.nikkei-italia.com/it/" },
        { image: Hisense, link: "https://www.hisense.it/" },
        { image: Berkel, link: "https://www.theberkelworld.com/it" },
        {
          image: ElectroluxProfessional,
          link: "https://www.electroluxprofessional.com/it/",
        },
        {
          image: SanGiorgio,
          link: "https://www.sangiorgioelettrodomestici.it/",
        },
        { image: Elba, link: "https://italia.elba-cookers.com/" },
        { image: Avintage, link: "https://avintage.com/en/" },
        { image: LG, link: "https://www.lg.com/it/" },
      ],
    },
    {
      title: "Linea Accessori",
      desc: "Una vasta gamma di accessori delle migliori marche",
      products: [
        { image: CIAC, link: "https://www.consorziociac.it/" },
        {
          image: SIDERPLAST,
          link: "https://siderplast.it/",
        },
        { image: Canali, link: "http://www.opes.it/company.php" },
      ],
    },
  ];

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };
  const togglePlayPauseProducts = () => {
    setIsProductsPlaying(!isProductsPlaying);
  };
  return (
    <div className="home">
      <Navbar></Navbar>
      <div className="homeBg">
        <div className="imageSliderComponent">
          <ImageSlider
            images={images}
            currentImageIndex={currentImageIndex}
            setCurrentImageIndex={setCurrentImageIndex}
            isPlaying={isPlaying}
          />
          <CurrentImageIndicator
            currentImageIndex={currentImageIndex}
            totalImages={images.length}
            togglePlayPause={togglePlayPause}
            isPlaying={isPlaying}
            className={"currentIndicatorMain"}
          />
        </div>
        <p className="homeText">
          Come concessionario esclusivo di Electrolux e AEG in Campania e
          Basilicata, e di Candy in quattro regioni, offriamo una vasta
          selezione di elettrodomestici da incasso e da libera installazione.
          Completate la vostra cucina con accessori di alta classe. Eleganza,
          efficienza e qualità: tutto ciò che la vostra cucina merita.
        </p>
        <div className="imageSliderComponent">
          <h2>Prodotti</h2>
          <ProductsSlider
            imageGroups={products}
            currentGroupIndex={currentGroupIndex}
            setCurrentGroupIndex={setCurrentGroupIndex}
            isPlaying={isProductsPlaying}
          />
          <CurrentProductIndicator
            currentGroupIndex={currentGroupIndex}
            totalImages={products.length}
            togglePlayPauseProducts={togglePlayPauseProducts}
            isPlaying={isProductsPlaying}
            className={"currentIndicatorProducts"}
          />
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
